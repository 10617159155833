import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { graphql } from 'gatsby'
import Stage from '@components/stage'
import ToC from '@components/toc'
import { createTocList } from '@tools/componentParser'
import ContentfulComponentArray from '@tools/ContentfulComponentArray'
import Container from '@objects/container'
import Copy from '@objects/copy'

function UeberUns({ pageContext, data }) {
  const intl = useIntl()

  const articleintro =
    data.contentfulArticle?.introCopy?.childMarkdownRemark?.html
  const articlecontent = data.contentfulArticle?.copy?.json?.content

  let tocItems = articleintro
    ? [
        {
          id: intl
            .formatMessage({ id: 'footer.about' })
            .replace(/\s+/g, '')
            .toLowerCase(),
          label: intl.formatMessage({ id: 'footer.about' }),
        },
      ].concat(createTocList(articlecontent, pageContext.locale))
    : createTocList(articlecontent, pageContext.locale)

  if (!tocItems) {
    tocItems = []
  }

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page.title}
        ariaLabel={pageContext.page.title}
      />
      <ToC items={tocItems}>
        {articleintro && (
          <Container id={tocItems[0].id} sidebar ariaLabel="arialabel.ueberuns">
            <Copy type="intro">{articleintro}</Copy>
          </Container>
        )}
        {articlecontent && (
          <ContentfulComponentArray componentarray={articlecontent} toc />
        )}
      </ToC>
    </>
  )
}

UeberUns.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default UeberUns

export const pageQuery = graphql`
  query UberUnsQuery($locale: String!) {
    contentfulArticle(node_locale: { eq: $locale }, slug: { eq: "ueber-uns" }) {
      id
      introCopy {
        childMarkdownRemark {
          html
        }
      }
      copy {
        json
      }
    }
  }
`
